import MobileMenu from '@/ctf-components/header/mobile-header/mobile-menu';
import { useInCyberHeaderNavigation } from '@/hooks/useInCyberNavigation';
import LogoImage from '@/public/incyber-logo.svg';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

const InCyberHeaderMobile = () => {
  const router = useRouter();
  const { locale } = useRouter();
  const { data, isLoading } = useInCyberHeaderNavigation(locale);
  const { items } = data?.headerCollection?.items[0]?.menuItemsCollection || [];
  const [menuVisible, setMenuVisible] = React.useState(false);

  const handleShowMenu = () => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    setMenuVisible(false);
  }, [router.asPath]);

  if (isLoading) return <div>Loading</div>;

  return (
    <div className="HeaderMobile w-full flex flex-row items-center justify-between">
      <Link href="/">
        <Image src={LogoImage} alt="Load more" width={214} height={60} />
      </Link>

      {!menuVisible && (
        <button
          type="button"
          className="flex flex-col"
          // className={`ml-3 ${styles.button}`}
          onClick={() => handleShowMenu()}
        >
          <svg width="50" height="80" xmlns="http://www.w3.org/2000/svg">
            <line
              x1="10"
              y1="35"
              x2="45"
              y2="35"
              stroke="#3cd52e"
              strokeWidth="4"
            />

            <line
              x1="20"
              y1="45"
              x2="45"
              y2="45"
              stroke="#3cd52e"
              strokeWidth="4"
            />

            {/* <line
              x1="10"
              y1="50"
              x2="80"
              y2="50"
              stroke="#3cd52e"
              strokeWidth="4"
            /> */}
          </svg>
        </button>
      )}

      {menuVisible && (
        <MobileMenu items={items} closeMenuHandler={handleShowMenu} />
      )}
    </div>
  );
};

export default InCyberHeaderMobile;
